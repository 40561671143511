import { Add, Delete, Remove } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

export type ItemType = {
    id: number;
    name: string;
    isCustom?: boolean;
};

type ItemProps = {
    count?: number;
    onChange?: (count: number) => any;
    readonly: boolean;
    onDelete?: () => void;
};

export const Item = (props: ItemType & ItemProps) => {
    const onChange = (count: number) => {
        if (props.onChange) props.onChange(count);
    };

    return (
        <div className={styles.item}>
            <div>{props.name}</div>
            <div>
                <ItemButton count={props.count} onChange={onChange} readonly={props.readonly} />
            </div>
            {props.isCustom ? (
                <div className={styles.delete} onClick={() => props.onDelete?.()}>
                    <Delete />
                </div>
            ) : null}
        </div>
    );
};

const ItemButton = (props: { count?: number; onChange?: (count: number) => any; readonly: boolean }) => {
    const [count, setCount] = useState(props.count ?? 0);

    useEffect(() => {
        setCount(props.count ?? 0);
    }, [props]);

    useEffect(() => {
        if (props.onChange) props.onChange(count);
    }, [count]);

    if (props.readonly) {
        return <div className={styles.itemButton}>{count}</div>;
    }

    if (count === 0) {
        return (
            <div className={styles.itemButton}>
                <div className={styles.button} onClick={() => setCount(count + 1)}>
                    <Add />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.itemButton}>
            <div className={styles.button} onClick={() => setCount(count + 1)}>
                <Add />
            </div>
            {count}
            <div className={styles.button} onClick={() => setCount(count - 1)}>
                <Remove />
            </div>
        </div>
    );
};

// const styles = StyleSheet.create({

//     item: {
//         display: 'flex',
//         width: '100%',
//         minHeight: 100,
//         padding: 5,
//         borderRadius: 20,
//         flexDirection: 'row',
//         justifyContent: 'center',
//         backgroundColor: '#ffffff',
//         marginVertical: 8,
//     },

//     title: {
//         color: '#000000',
//         fontSize: 20,
//     },

//     price: {
//         color: '#9a9a9a',
//         fontSize: 17,
//     },

//     image: {
//         width: 90,
//         height: 90,
//         borderRadius: 16,
//     },
// });
