import { gql, useQuery } from '@apollo/client';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { useState } from 'react';
import PersonSelector from '../../components/PersonSelector';
import styles from './index.module.scss';

const GET_HOUSES = gql`
    query {
        houses {
            id
            name
            houseNumber
            person {
                id
                name
            }
        }
    }
`;

export default function HousePlan() {
    const [selectedPerson, setSelectedPerson] = useState(-1);
    const { data: housesData } = useQuery<{
        houses: {
            id: number;
            name: string;
            houseNumber: string;
            person: {
                id: number;
                name: string;
            }[];
        }[];
    }>(GET_HOUSES);

    const houses = housesData?.houses
        ? housesData.houses
              .filter((house) => selectedPerson === -1 || house.person.some((person) => person.id === selectedPerson))
              .map((house) => (
                  <div className={styles.house}>
                      <div className={styles.houseTitle}>
                          {house.houseNumber} ({house.name})
                      </div>
                      <div>
                          {house.person
                              .slice()
                              .sort((a, b) => {
                                  if (a.name < b.name) return -1;
                                  if (a.name > b.name) return 1;
                                  return 0;
                              })
                              .map((person) => (
                                  <div>{person.name}</div>
                              ))}
                      </div>
                  </div>
              ))
        : null;

    return (
        <Container>
            <Header title="Hausplan" subtitle="Wen suchst du?" />
            <Container>
                <Row>
                    <Col>
                        <PersonSelector
                            onSubmit={(values) => setSelectedPerson(values[0] ? values[0].id : -1)}
                            placeholder="Wen suchst du?"
                            emptyLabel="Kenne ich nicht..."
                        />
                    </Col>
                </Row>
                <div className={styles.houses}>{houses}</div>
            </Container>
        </Container>
    );
}
