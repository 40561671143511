import { Container } from 'react-bootstrap';
import styles from './index.module.scss';
import { Close, Menu } from '@mui/icons-material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type HamburgerProps = {
    items: { label: string; link: string }[];
    onLogout?: () => void;
};

type ItemProps = {
    className?: string;
    item: { label: string; link: string };
};

export default function Hamburger(props: HamburgerProps) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Container>
            {isOpen ? (
                <Close fontSize="large" className={styles.hamburger} onClick={() => setIsOpen(false)} />
            ) : (
                <Menu fontSize="large" className={styles.hamburger} onClick={() => setIsOpen(true)} />
            )}
            <Container className={`${styles.hamburgerPopup} ${isOpen ? styles.open : ''}`}>
                {props.items.map((item) => (
                    <HamburgerItem className={styles.item} item={item} key={item.label} />
                ))}
                <a className={styles.item} href="#" onClick={() => props.onLogout?.()}>
                    Ausloggen
                </a>
            </Container>
        </Container>
    );
}

function HamburgerItem(props: ItemProps) {
    return (
        <Link to={props.item.link} className={props.className}>
            {props.item.label}
        </Link>
    );
}
