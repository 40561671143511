import { gql, useQuery } from '@apollo/client';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Loader } from '../Loader';
// import styles from './index.module.scss';

type Person = {
    id: number;
    name: string;
};

type Props = {
    className?: string;
    defaultOpen?: boolean;
    onSubmit?: (value: Person[]) => void;
    placeholder?: string;
    emptyLabel?: string;
    where?: {
        houseId?: number;
        familyGroupId?: number;
    };
    exclude?: number[];
};

const GET_PERSONS = gql`
    query($houseId: Int, $familyGroupId: Int) {
        persons(houseId: $houseId, familyGroupId: $familyGroupId) {
            id
            name
        }
    }
`;

export default function PersonSelector(props: Props) {
    const { data } = useQuery<{ persons: Person[] }>(GET_PERSONS, {
        variables: {
            houseId: props.where?.houseId ?? undefined,
            familyGroupId: props.where?.familyGroupId ?? undefined,
        },
    });

    return (
        <>
            {data ? (
                <Typeahead
                    className={props.className}
                    defaultOpen={props.defaultOpen}
                    id="Name"
                    labelKey="name"
                    options={data.persons
                        .filter((person) => !(props.exclude && props.exclude.includes(person.id)))
                        .sort((a, b) => a.name.localeCompare(b.name))}
                    placeholder={props.placeholder}
                    onChange={(values) => props.onSubmit?.(values)}
                    emptyLabel={props.emptyLabel}
                />
            ) : (
                <Loader />
            )}
        </>
    );
}
