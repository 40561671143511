import { Item, ItemType } from './Item';
import styles from './index.module.scss';
import { Add } from '@mui/icons-material';
import Popup from '../Popup';
import dayjs from 'dayjs';
import { useState } from 'react';
import { DateTimePicker } from '../DateTimePicker';

require('dayjs/locale/de');
dayjs.locale('de');

type Props = {
    data: ItemType[];
    onAdd?: (item: { deadline: dayjs.Dayjs }) => void;
};

export function CrudList(props: Props) {
    const [popupOpen, setPopupOpen] = useState(false);

    const items = props.data.map((itemData) => {
        return <Item key={itemData.id} {...itemData} />;
    });

    return (
        <div className={styles.crudList}>
            <div className={styles.listMenu}>
                <Add
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setPopupOpen(true);
                    }}
                />
            </div>
            <Popup isOpen={popupOpen} onClose={() => setPopupOpen(false)}>
                <AddItem
                    onAdd={(item) => {
                        props.onAdd?.(item);
                        setPopupOpen(false);
                    }}
                />
            </Popup>
            {items}
        </div>
    );
}

function AddItem(props: { onAdd: (item: { deadline: dayjs.Dayjs }) => void }) {
    const [dateTime, setDateTime] = useState<dayjs.Dayjs | null>(null);

    return (
        <>
            <DateTimePicker onChange={(newDateTime) => setDateTime(newDateTime)} />
            <button
                onClick={() => {
                    if (dateTime) {
                        props.onAdd({
                            deadline: dateTime,
                        });
                    }
                }}
            >
                Hinzufügen
            </button>
        </>
    );
}
