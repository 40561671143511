import * as dotenv from 'dotenv';

dotenv.config();

export default {
    /**
     * Url of the api
     */
    apiUrl: process.env.REACT_APP_API_URL,
    gfDate: {
        start: '2023-10-21',
        end: '2023-10-28',
    },
};
