import { Item, ItemType } from './Item';
import styles from './index.module.scss';

export function GroceryList(props: {
    data: ItemType[];
    state: { [key: number]: boolean };
    onChange: (state: { [key: number]: boolean }) => any;
    readonly?: boolean;
}) {
    const getComponents = (items: ItemType[]) => {
        return items.map((itemData) => {
            return (
                <Item
                    key={itemData.id}
                    {...itemData}
                    count={itemData.quantity}
                    state={props.state[itemData.id]}
                    onChange={(state: boolean) => {
                        props.onChange({
                            [itemData.id]: state,
                        });
                    }}
                    readonly={props.readonly ?? false}
                />
            );
        });
    };

    const uncheckedItems = getComponents(props.data.filter((itemData) => props.state[itemData.id] === false));
    const checkedItems = getComponents(props.data.filter((itemData) => props.state[itemData.id] === true));
    return (
        <div className={styles.offerList}>
            {uncheckedItems}
            <hr />
            {checkedItems}
        </div>
    );
}
