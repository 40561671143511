import { gql, useQuery } from '@apollo/client';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

const GET_GROCERY_LIST = gql`
    query($purchaseId: Int!) {
        groceryList(purchaseId: $purchaseId) {
            product {
                id
                name
            }
            quantity
            isPurchased
            house {
                id
                name
            }
        }
    }
`;

export default function GroceryHouseList() {
    const params = useParams<{ purchaseId: string }>();
    const purchaseId = parseInt(params.purchaseId);

    const { data: groceryListData } = useQuery<{
        groceryList: {
            product: {
                id: number;
                name: string;
            };
            quantity: number;
            isPurchased: boolean;
            house: {
                id: number;
                name: string;
            };
        }[];
    }>(GET_GROCERY_LIST, { variables: { purchaseId } });

    const houses = groceryListData?.groceryList
        ? uniqueBy(
              groceryListData.groceryList.filter((item) => item.quantity > 0).map((item) => item.house),
              (item) => item.id
          ).sort((a, b) => b.id - a.id)
        : [];

    return (
        <Container>
            <Header title="Einkaufsliste" />
            <Container>
                <Row>
                    <Col>
                        {houses.map((house) => (
                            <div>
                                {house.name}
                                <div className={styles.list}>
                                    {groceryListData?.groceryList
                                        ? groceryListData.groceryList
                                              .filter((item) => item.house.id === house.id)
                                              .filter((item) => item.quantity > 0)
                                              .map((item) => (
                                                  <div className={styles.item}>
                                                      <div>{item.product.name}</div>
                                                      <div>{item.quantity}</div>
                                                  </div>
                                              ))
                                        : null}
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

const uniqueBy = function <T>(array: T[], mapFunction: (item: T) => any): T[] {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if (!map.has(mapFunction(item))) {
            map.set(mapFunction(item), true);

            result.push(item);
        }
    }

    return result;
};
