import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Add, Search } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { OfferList } from '../../components/OfferList';
import { PersonContext } from '../../contexts/PersonContext';
import styles from './index.module.scss';
import offerListItemStyles from '../../components/OfferList/index.module.scss';
import { ErrorToast } from '../../components/ErrorToast';

require('dayjs/locale/de');
dayjs.locale('de');

const GET_PRODUCTS = gql`
    {
        products {
            id
            name
            isCustom
        }
    }
`;

const GET_CURRENT_PURCHASE = gql`
    query {
        currentPurchase {
            id
            deadline
        }
    }
`;

const GET_GROCERY_LIST = gql`
    query($houseId: Int, $purchaseId: Int!) {
        groceryList(houseId: $houseId, purchaseId: $purchaseId) {
            product {
                id
                name
            }
            quantity
        }
    }
`;

const CHANGE_GROCERY_LIST = gql`
    mutation($houseId: Int!, $productId: Int!, $purchaseId: Int!, $quantity: Int!) {
        changeGroceryListItem(houseId: $houseId, productId: $productId, purchaseId: $purchaseId, quantity: $quantity) {
            quantity
        }
    }
`;

const CREATE_PRODUCT = gql`
    mutation($name: String!) {
        createCustomProduct(name: $name) {
            id
            name
            isCustom
        }
    }
`;

const DELETE_PRODUCT = gql`
    mutation($id: Int!) {
        deleteProduct(id: $id) {
            id
        }
    }
`;

export default function Order() {
    const [person] = useContext(PersonContext);
    const [filter, setFilter] = useState('');
    const [cart, setCart] = useState<{ [key: number]: number }>({});
    const { data: productData } = useQuery<{ products: { id: number; name: string; isCustom: boolean }[] }>(
        GET_PRODUCTS
    );
    const { data: purchaseData } = useQuery<{ currentPurchase: { id: number; deadline: string } }>(
        GET_CURRENT_PURCHASE
    );
    const [getGroceryList, { data: groceryListData }] = useLazyQuery<{
        groceryList: {
            product: {
                id: number;
                name: string;
            };
            quantity: number;
        }[];
    }>(GET_GROCERY_LIST);
    const [changeGroceryListItem] = useMutation(CHANGE_GROCERY_LIST);
    const [createProduct] = useMutation<{
        createCustomProduct: { id: number; name: string; isCustom: boolean };
    }>(CREATE_PRODUCT);
    const [deleteProduct] = useMutation<{ deleteProduct: { id: number } }>(DELETE_PRODUCT);
    const [products, setProducts] = useState<{ id: number; name: string; isCustom: boolean }[]>([]);
    const [error, setError] = useState('');

    const changedCart = (newItem: { [key: number]: number }) => {
        Object.keys(newItem).map((key) => {
            changeGroceryListItem({
                variables: {
                    houseId: person?.houseId,
                    productId: parseInt(key),
                    purchaseId: purchaseData?.currentPurchase.id,
                    quantity: newItem[parseInt(key)],
                },
            });
        });

        setCart({
            ...cart,
            ...newItem,
        });
    };

    useEffect(() => {
        if (purchaseData?.currentPurchase)
            getGroceryList({ variables: { houseId: person?.houseId, purchaseId: purchaseData.currentPurchase.id } });
    }, [purchaseData]);

    useEffect(() => {
        if (!groceryListData?.groceryList) return;

        const data: { [key: number]: number } = {};

        groceryListData.groceryList.forEach((item) => {
            data[item.product.id] = item.quantity;
        });

        setCart(data);
    }, [groceryListData]);

    useEffect(() => {
        if (productData?.products) setProducts(productData.products);
    }, [productData]);

    const addProduct = async (name: string) => {
        const newProductData = await createProduct({ variables: { name } });
        if (newProductData.data?.createCustomProduct)
            setProducts([...products, newProductData.data.createCustomProduct]);
    };

    const removeProduct = async (id: number) => {
        try {
            const newProductData = await deleteProduct({ variables: { id } });
            if (newProductData.data?.deleteProduct) setProducts(products.filter((product) => product.id !== id));
        } catch (e) {
            setError('Produkt kann nicht entfernt werden');
            console.error(e);
        }
    };

    return (
        <Container>
            <Header title="Bestellung" />
            <ErrorToast message={error} clearError={() => setError('')} />
            <Container>
                {purchaseData?.currentPurchase === null ? (
                    <Row>
                        <Col>Anscheinend ist kein Einkauf mehr geplant...</Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <div className={styles.searchBarWrapper}>
                                    <input
                                        type="text"
                                        className={styles.searchBar}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                    <Search className={styles.searchIcon} />
                                </div>
                                <div>
                                    {purchaseData?.currentPurchase
                                        ? 'Deadline: ' +
                                          dayjs(parseInt(purchaseData.currentPurchase.deadline)).format(
                                              'dd, DD.MM. HH:mm'
                                          )
                                        : null}
                                </div>
                                {products && groceryListData?.groceryList ? (
                                    <OfferList
                                        data={products}
                                        filter={filter}
                                        cart={cart}
                                        onChange={(newItem) => changedCart(newItem)}
                                        onDelete={(id: number) => removeProduct(id)}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr />
                                <AddProduct onSubmit={(name: string) => addProduct(name)} />
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </Container>
    );
}

const AddProduct = (props: { onSubmit?: (name: string) => void }) => {
    const [name, setName] = useState('');

    const submit = (name: string) => {
        if (name === '') return;

        props.onSubmit?.(name);
        setName('');
    };

    return (
        <div className={offerListItemStyles.item}>
            <div>
                <input
                    className={styles.input}
                    placeholder="Sonstiges"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            submit(name);
                        }
                    }}
                />
            </div>
            <div>
                <div className={offerListItemStyles.itemButton}>
                    <div className={offerListItemStyles.button} onClick={() => submit(name)}>
                        <Add />
                    </div>
                </div>
            </div>
        </div>
    );
};
