import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Check, Delete, Edit } from '@mui/icons-material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { Loader } from '../../components/Loader';
import PersonSelector from '../../components/PersonSelector';
import WeekSchedule from '../../components/WeekSchedule';
import config from '../../config';
import { PersonContext } from '../../contexts/PersonContext';
import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

const GET_MEAL_PLAN = gql`
    query($familyGroupId: Int!) {
        mealPlan(familyGroupId: $familyGroupId) {
            date
            mealType
            person {
                id
                name
            }
        }
    }
`;

const ADD_PERSON_TO_MEAL_PLAN = gql`
    mutation($familyGroupId: Int!, $personId: Int!, $date: String!, $mealType: String!) {
        addPersonToMealPlan(familyGroupId: $familyGroupId, personId: $personId, date: $date, mealType: $mealType) {
            date
        }
    }
`;

const REMOVE_PERSON_FROM_MEAL_PLAN = gql`
    mutation($familyGroupId: Int!, $personId: Int!, $date: String!, $mealType: String!) {
        removePersonFromMealPlan(familyGroupId: $familyGroupId, personId: $personId, date: $date, mealType: $mealType) {
            date
        }
    }
`;

const dates = ((begin, end) => {
    const dates = [];
    const endDate = dayjs(end);

    let current = dayjs(begin);
    while (current <= endDate) {
        dates.push(current.clone());
        current = current.add(1, 'day');
    }

    return dates;
})(config.gfDate.start, config.gfDate.end);

export default function MealPlan() {
    const [person] = useContext(PersonContext);
    const [getMealPlan, { data }] = useLazyQuery<{
        mealPlan: { date: string; mealType: string; person: { id: number; name: string } }[];
    }>(GET_MEAL_PLAN, { variables: { familyGroupId: person?.familyGroupId }, fetchPolicy: 'cache-and-network' });

    const [addPersonToMealPlan] = useMutation(ADD_PERSON_TO_MEAL_PLAN);
    const [removePersonFromMealPlan] = useMutation(REMOVE_PERSON_FROM_MEAL_PLAN);

    const [isEditing, setIsEditing] = useState<{ date: number; mealType: string } | null>(null);
    const [mealPlan, setMealPlan] = useState<
        { date: string; mealType: string; person: { id: number; name: string } }[]
    >([]);

    useEffect(() => {
        getMealPlan();
    }, []);

    useEffect(() => {
        setMealPlan(data?.mealPlan ?? []);
    }, [data]);

    const reloadEditing = () => {
        const currentVal = isEditing ? Object.assign({}, isEditing) : null;
        setIsEditing(null);
        setTimeout(() => {
            setIsEditing(currentVal);
        }, 1);
    };

    const addPerson = async (personId: number, personName: string, date: dayjs.Dayjs, mealType: string) => {
        setMealPlan([
            ...mealPlan,
            {
                date: date.format('YYYY-MM-DD'),
                mealType,
                person: { id: personId, name: personName },
            },
        ]);

        reloadEditing();

        try {
            await addPersonToMealPlan({
                variables: {
                    familyGroupId: person?.familyGroupId,
                    personId,
                    date: date.format('YYYY-MM-DD'),
                    mealType,
                },
            });
        } catch (e) {
            await getMealPlan();
            console.error(e);
        }
    };

    const removePerson = async (personId: number, date: dayjs.Dayjs, mealType: string) => {
        setMealPlan(mealPlan.filter((item) => item.person.id !== personId));

        reloadEditing();

        try {
            await removePersonFromMealPlan({
                variables: {
                    familyGroupId: person?.familyGroupId,
                    personId,
                    date: date.format('YYYY-MM-DD'),
                    mealType,
                },
            });
        } catch (e) {
            await getMealPlan();
            console.error(e);
        }
    };

    const scheduleData = dates.map<{ label: string; entries: [React.ReactNode, React.ReactNode, React.ReactNode] }>(
        (date) => {
            const entries = mealPlan.filter((entry) => +dayjs(entry.date) === +date);

            const getPersonsByMealType = (mealType: string) => {
                const filteredEntries = entries.filter((entry) => entry.mealType === mealType);

                return (
                    <div className={styles.wrapper}>
                        <ul className={styles.personList}>
                            {filteredEntries.map((entry) => (
                                <li key={`${date.unix()} ${mealType} ${entry.person.id}`}>
                                    <span className={entry.person.id === person?.id ? styles.isPerson : ''}>
                                        {entry.person.name}
                                    </span>
                                    {isEditing?.date === +date && isEditing.mealType === mealType ? (
                                        <Delete onClick={() => removePerson(entry.person.id, date, mealType)} />
                                    ) : null}
                                </li>
                            ))}

                            {isEditing?.date === +date && isEditing.mealType === mealType ? (
                                <PersonSelector
                                    className={styles.selector}
                                    defaultOpen={entries.length === 0}
                                    onSubmit={(value) => {
                                        addPerson(value[0].id, value[0].name, date, mealType);
                                    }}
                                    where={{
                                        familyGroupId: person?.familyGroupId,
                                    }}
                                    exclude={filteredEntries.map((entry) => entry.person.id)}
                                    emptyLabel="Keiner gefunden"
                                    placeholder="Person hinzufügen"
                                />
                            ) : null}
                        </ul>
                        <div className={styles.utility}>
                            {isEditing?.date === +date && isEditing.mealType === mealType ? (
                                <Check
                                    onClick={() => {
                                        setIsEditing(null);
                                    }}
                                />
                            ) : (
                                <Edit
                                    onClick={() => {
                                        setIsEditing({ date: +date, mealType });
                                    }}
                                />
                            )}
                        </div>
                    </div>
                );
            };

            return {
                label: date.format('dd'),
                entries: [
                    getPersonsByMealType('Breakfast'),
                    getPersonsByMealType('Lunch'),
                    getPersonsByMealType('Dinner'),
                ],
            };
        }
    );

    return (
        <Container>
            <Header title="Essensplan" />
            <Container>
                <Row>
                    <Col>
                        {data?.mealPlan ? (
                            <WeekSchedule
                                entriesCount={3}
                                columnLabels={['Frühstück', 'Mittagessen', 'Abendbrot']}
                                data={scheduleData}
                            />
                        ) : (
                            <Loader />
                        )}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
