import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Check, Delete, Edit } from '@mui/icons-material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { Loader } from '../../components/Loader';
import PersonSelector from '../../components/PersonSelector';
import WeekSchedule from '../../components/WeekSchedule';
import config from '../../config';
import { PersonContext } from '../../contexts/PersonContext';
import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

const GET_CLEANING_SCHEDULE = gql`
    query($houseId: Int!) {
        cleaningSchedule(houseId: $houseId) {
            date
            person {
                id
                name
            }
        }
    }
`;

const ADD_PERSON_TO_CLEANING_SCHEDULE = gql`
    mutation($houseId: Int!, $personId: Int!, $date: String!) {
        addPersonToCleaningSchedule(houseId: $houseId, personId: $personId, date: $date) {
            date
        }
    }
`;

const REMOVE_PERSON_FROM_CLEANING_SCHEDULE = gql`
    mutation($houseId: Int!, $personId: Int!, $date: String!) {
        removePersonFromCleaningSchedule(houseId: $houseId, personId: $personId, date: $date) {
            date
        }
    }
`;

const dates = ((begin, end) => {
    const dates = [];
    const endDate = dayjs(end);

    let current = dayjs(begin);
    while (current <= endDate) {
        dates.push(current.clone());
        current = current.add(1, 'day');
    }

    return dates;
})(config.gfDate.start, config.gfDate.end);

export default function CleaningSchedule() {
    const [person] = useContext(PersonContext);
    const [getCleaningSchedule, { data }] = useLazyQuery<{
        cleaningSchedule: { date: string; person: { id: number; name: string } }[];
    }>(GET_CLEANING_SCHEDULE, { variables: { houseId: person?.houseId }, fetchPolicy: 'cache-and-network' });

    const [addPersonToCleaningSchedule] = useMutation(ADD_PERSON_TO_CLEANING_SCHEDULE);
    const [removePersonFromCleaningSchedule] = useMutation(REMOVE_PERSON_FROM_CLEANING_SCHEDULE);

    const [isEditing, setIsEditing] = useState(-1);
    const [cleaningSchedule, setCleaningSchedule] = useState<{ date: string; person: { id: number; name: string } }[]>(
        []
    );

    useEffect(() => {
        getCleaningSchedule();
    }, []);

    useEffect(() => {
        setCleaningSchedule(data?.cleaningSchedule ?? []);
    }, [data]);

    const reloadEditing = () => {
        const currentVal = isEditing;
        setIsEditing(-1);
        setTimeout(() => {
            setIsEditing(currentVal);
        }, 1);
    };

    const addPerson = async (personId: number, personName: string, date: dayjs.Dayjs) => {
        setCleaningSchedule([
            ...cleaningSchedule,
            {
                date: date.format('YYYY-MM-DD'),
                person: { id: personId, name: personName },
            },
        ]);

        reloadEditing();

        try {
            await addPersonToCleaningSchedule({
                variables: {
                    houseId: person?.houseId,
                    personId: personId,
                    date: date.format('YYYY-MM-DD'),
                },
            });
        } catch (e) {
            await getCleaningSchedule();
            console.error(e);
        }
    };

    const removePerson = async (personId: number, date: dayjs.Dayjs) => {
        setCleaningSchedule(cleaningSchedule.filter((item) => item.person.id !== personId));

        reloadEditing();

        try {
            await removePersonFromCleaningSchedule({
                variables: {
                    houseId: person?.houseId,
                    personId: personId,
                    date: date.format('YYYY-MM-DD'),
                },
            });
        } catch (e) {
            await getCleaningSchedule();
            console.error(e);
        }
    };

    const scheduleData = dates.map<{ label: string; entries: [React.ReactNode] }>((date) => {
        const entries = cleaningSchedule.filter((entry) => +dayjs(entry.date) === +date);

        return {
            label: date.format('dd'),
            entries: [
                <div className={styles.wrapper}>
                    <ul className={styles.personList}>
                        {entries.map((entry) => (
                            <li key={`${date.unix()} ${entry.person.id}`}>
                                <span className={entry.person.id === person?.id ? styles.isPerson : ''}>
                                    {entry.person.name}
                                </span>
                                {isEditing === +date ? (
                                    <Delete onClick={() => removePerson(entry.person.id, date)} />
                                ) : null}
                            </li>
                        ))}
                        {isEditing === +date ? (
                            <PersonSelector
                                className={styles.selector}
                                defaultOpen={entries.length === 0}
                                onSubmit={(value) => {
                                    addPerson(value[0].id, value[0].name, date);
                                }}
                                where={{
                                    houseId: person?.houseId,
                                }}
                                exclude={entries.map((entry) => entry.person.id)}
                                emptyLabel="Keiner gefunden"
                                placeholder="Person hinzufügen"
                            />
                        ) : null}
                    </ul>
                    <div className={styles.utility}>
                        {isEditing === +date ? (
                            <Check
                                onClick={() => {
                                    setIsEditing(-1);
                                }}
                            />
                        ) : (
                            <Edit
                                onClick={() => {
                                    setIsEditing(+date);
                                }}
                            />
                        )}
                    </div>
                </div>,
            ],
        };
    });

    return (
        <Container>
            <Header title="Putzplan" />
            <Container>
                <Row>
                    <Col>
                        {data?.cleaningSchedule ? <WeekSchedule entriesCount={1} data={scheduleData} /> : <Loader />}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
