import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { GroceryList as GroceryListComponent } from '../../components/GroceryList';
import { PersonContext } from '../../contexts/PersonContext';
import dayjs from 'dayjs';
import { Link, useParams } from 'react-router-dom';
import { DateTimePicker } from '../../components/DateTimePicker';
import { Loader } from '../../components/Loader';
// import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

const GET_PURCHASE = gql`
    query($id: Int!) {
        purchase(id: $id) {
            id
            state
            deadline
        }
    }
`;

const CHANGE_PURCHASE = gql`
    mutation($id: Int!, $deadline: String, $state: String) {
        updatePurchase(id: $id, deadline: $deadline, state: $state) {
            id
        }
    }
`;

const GET_GROCERY_LIST = gql`
    query($purchaseId: Int!) {
        groceryList(purchaseId: $purchaseId) {
            product {
                id
                name
            }
            quantity
            isPurchased
        }
    }
`;

const CHANGE_GROCERY_LIST_STATUS = gql`
    mutation($houseId: Int!, $productId: Int!, $purchaseId: Int!, $isPurchased: Boolean!) {
        changeGroceryListItemStatus(
            houseId: $houseId
            productId: $productId
            purchaseId: $purchaseId
            isPurchased: $isPurchased
        ) {
            isPurchased
        }
    }
`;

export default function GroceryList() {
    const params = useParams<{ purchaseId: string }>();
    const purchaseId = parseInt(params.purchaseId);

    const [person] = useContext(PersonContext);
    const [state, setState] = useState<{ [key: number]: boolean }>({});
    const { data: groceryListData } = useQuery<{
        groceryList: {
            product: {
                id: number;
                name: string;
            };
            quantity: number;
            isPurchased: boolean;
        }[];
    }>(GET_GROCERY_LIST, { variables: { houseId: person?.houseId, purchaseId } });
    const { data: purchaseData } = useQuery<{
        purchase: {
            id: number;
            state: 'Open' | 'InProgress' | 'Close';
            deadline: string;
        };
    }>(GET_PURCHASE, { variables: { id: purchaseId } });
    const [changeGroceryListItemStatus] = useMutation(CHANGE_GROCERY_LIST_STATUS);
    const [changePurchase] = useMutation(CHANGE_PURCHASE);

    const changedState = (newState: { [key: number]: boolean }) => {
        Object.keys(newState).map((key) => {
            changeGroceryListItemStatus({
                variables: {
                    houseId: person?.houseId,
                    productId: parseInt(key),
                    purchaseId,
                    isPurchased: newState[parseInt(key)],
                },
            });
        });

        setState({ ...state, ...newState });
    };

    const changePurchaseState = (id: number, state: string) => {
        changePurchase({
            variables: {
                id,
                state,
            },
        });
    };

    useEffect(() => {
        if (!groceryListData?.groceryList) return;

        const data: { [key: number]: boolean } = {};

        groceryListData.groceryList.forEach((item) => {
            data[item.product.id] = item.isPurchased;
        });

        setState(data);
    }, [groceryListData]);

    return (
        <Container>
            <Header title="Einkaufsliste" />
            <Container>
                <Row>
                    <Col style={{ display: 'flex', flexDirection: 'column' }}>
                        {purchaseData?.purchase ? (
                            <>
                                <DateTimePicker
                                    defaultDateTime={dayjs(parseInt(purchaseData.purchase.deadline))}
                                    onChange={(dateTime) =>
                                        changePurchase({
                                            variables: {
                                                id: purchaseData.purchase.id,
                                                deadline: dateTime.toISOString(),
                                            },
                                        })
                                    }
                                />
                                Status:
                                <select
                                    onChange={(e) => {
                                        changePurchaseState(purchaseData.purchase.id, e.target.value);
                                    }}
                                    defaultValue={purchaseData.purchase.state}
                                >
                                    <option value="Open">Offen</option>
                                    <option value="InProgress">Am einkaufen</option>
                                    <option value="Close">Abgeschlossen</option>
                                </select>
                            </>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {groceryListData?.groceryList ? (
                            <GroceryListComponent
                                data={groceryListData?.groceryList
                                    .filter((item) => item.quantity > 0)
                                    .map((item) => ({
                                        ...item.product,
                                        quantity: item.quantity,
                                    }))
                                    .reduce<
                                        {
                                            quantity: number;
                                            id: number;
                                            name: string;
                                        }[]
                                    >((list, item) => {
                                        if (list.some((a) => a.id === item.id)) {
                                            return list.map((a) =>
                                                a.id === item.id ? { ...a, quantity: a.quantity + item.quantity } : a
                                            );
                                        } else {
                                            return [...list, item];
                                        }
                                    }, [])}
                                state={state}
                                readonly={true}
                                onChange={(newState) => changedState(newState)}
                            />
                        ) : (
                            <Loader />
                        )}
                        <Link to="grocery-house-list">
                            <Button>Zur Häuserliste</Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
