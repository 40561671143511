import styles from './index.module.scss';
import { Close } from '@mui/icons-material';

type Props = {
    isOpen: boolean;
    onClose?: () => void;
    children?: React.ReactNode | React.ReactNode[] | null;
};

export default function Popup(props: Props) {
    const close = () => {
        props.onClose?.();
    };

    return (
        <div>
            {props.isOpen ? (
                <div className={styles.popupWrapper} onClick={() => close()}>
                    <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.popupControls}>
                            <Close className={styles.popupControl} onClick={() => close()} />
                        </div>
                        <div className={styles.popupContent}>{props?.children}</div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
