import { simpleSearch } from '../../services/array-search';
import styles from './index.module.scss';
import { Item, ItemType } from './Item';

export function OfferList(props: {
    data: ItemType[];
    cart: { [key: number]: number };
    onChange: (cartData: { [key: number]: number }) => any;
    readonly?: boolean;
    filter?: string;
    onDelete?: (id: number) => void;
}) {
    const items = simpleSearch(props.data, props.filter ?? '', (elm) => elm.name).map((itemData) => {
        return (
            <Item
                key={itemData.id}
                {...itemData}
                count={props.cart[itemData.id]}
                onChange={(count: number) => {
                    props.onChange({
                        [itemData.id]: count,
                    });
                }}
                readonly={props.readonly ?? false}
                onDelete={() => props.onDelete?.(itemData.id)}
            />
        );
    });

    return <div className={styles.offerList}>{items}</div>;
}
