import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import config from '../../config';
// import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

type Props = {
    defaultDateTime?: dayjs.Dayjs;
    onChange?: (dateTime: dayjs.Dayjs) => void;
};

export function DateTimePicker(props: Props) {
    const [date, setDate] = useState(props.defaultDateTime?.format('YYYY-MM-DD') ?? '');
    const [time, setTime] = useState(props.defaultDateTime?.format('HH:mm') ?? '');

    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (date !== '' && time !== '') props.onChange?.(dayjs(`${date}T${time}:00`));
    }, [date, time]);

    return (
        <div>
            <div>
                Datum
                <Typeahead
                    options={((begin, end) => {
                        const dates = [];
                        const endDate = dayjs(end);

                        let current = dayjs(begin);
                        while (current <= endDate) {
                            dates.push(current.clone());
                            current = current.add(1, 'day');
                        }

                        return dates.map((date) => ({
                            id: date.format('YYYY-MM-DD'),
                            label: date.format('dd, DD.MM.'),
                        }));
                    })(config.gfDate.start, config.gfDate.end)}
                    defaultSelected={
                        props.defaultDateTime
                            ? [
                                  {
                                      id: props.defaultDateTime.format('YYYY-MM-DD'),
                                      label: props.defaultDateTime.format('dd, DD.MM.'),
                                  },
                              ]
                            : undefined
                    }
                    onChange={(values) => setDate(values.length > 0 ? values[0].id : '')}
                />
            </div>
            <div>
                Uhrzeit
                <Typeahead
                    options={(() => {
                        const times = [];
                        const endTime = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

                        let current = endTime.set('hour', 0).set('minute', 0).set('second', 0);
                        while (+current <= +endTime) {
                            times.push(current.clone());
                            current = current.add(30, 'minutes');
                        }

                        return times.map((date) => ({
                            id: date.format('HH:mm'),
                            label: date.format('HH:mm'),
                        }));
                    })()}
                    defaultSelected={
                        props.defaultDateTime
                            ? [
                                  {
                                      id: props.defaultDateTime.format('HH:mm'),
                                      label: props.defaultDateTime.format('HH:mm'),
                                  },
                              ]
                            : undefined
                    }
                    onChange={(values) => setTime(values.length > 0 ? values[0].id : '')}
                />
            </div>
        </div>
    );
}
