const getPriority = (text: string, needle: string) => {
    text = text.toLowerCase();
    needle = needle.toLowerCase();

    if (text === needle) return 100;

    if (text.startsWith(needle)) return 80;

    if (text.search(needle) >= 0) return 60;

    return 1;
};

export const simpleSearch = <T extends {}>(haystack: T[], needle: string, mapFunction: (element: T) => string): T[] => {
    const pattern = needle
        .split('')
        .map((char) => {
            return `(?=.*${char})`;
        })
        .join('');
    const regex = new RegExp(`${pattern}`, 'gi');

    return haystack
        .filter((element) => regex.test(mapFunction(element)))
        .sort((a, b) => getPriority(mapFunction(b), needle) - getPriority(mapFunction(a), needle));
};
