import React, { createContext, useEffect, useMemo, useState } from 'react';

type Person = {
    id: number;
    name: string;
    houseId: number;
    familyGroupId: number;
    isAdmin: boolean;
};

type ContextType = Person | null;

type StateArray<Type> = [Type, React.Dispatch<React.SetStateAction<Type>>];

export const PersonContext = createContext<StateArray<ContextType>>([null, () => {}]);

const lastLogin = localStorage.getItem('lastLogin');
const lastLoginWithin6Months =
    lastLogin && new Date().getTime() - new Date(parseInt(lastLogin)).getTime() < 1000 * 60 * 60 * 24 * 30 * 6;
const personLoggedIn = lastLoginWithin6Months ? localStorage.getItem('personLoggedIn') ?? 'null' : 'null';

export const PersonContextProvider = (props: { children: React.ReactNode | React.ReactNode[] | null }) => {
    const [person, setPerson] = useState<ContextType>(JSON.parse(personLoggedIn));

    useEffect(() => {
        localStorage.setItem('lastLogin', new Date().getTime().toString());
        localStorage.setItem('personLoggedIn', JSON.stringify(person));
    }, [person]);

    const value = useMemo<StateArray<ContextType>>(() => [person, setPerson], [person]);

    return <PersonContext.Provider value={value}>{props.children}</PersonContext.Provider>;
};
