import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

export type ItemType = {
    id: number;
    deadline: string;
};

type ItemProps = {
    onChange?: (state: boolean) => any;
    readonly?: boolean;
};

export const Item = (props: ItemType & ItemProps) => {
    return (
        <Link to={`/purchase/${props.id}/grocery-list`} style={{ color: '#000', textDecoration: 'none' }}>
            <div className={styles.item}>
                <div>{dayjs(parseInt(props.deadline)).format('dd, DD.MM HH:mm')}</div>
            </div>
        </Link>
    );
};
