import { gql, useLazyQuery } from '@apollo/client';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import { useContext, useEffect } from 'react';
import { PersonContext } from '../../contexts/PersonContext';
import PersonSelector from '../../components/PersonSelector';
// import styles from './index.module.scss';

const GET_PERSON = gql`
    query GetPerson($id: Int!) {
        person(id: $id) {
            id
            name
            isAdmin
            house {
                id
                familyGroupId
            }
        }
    }
`;

export default function Login() {
    const [person, setPerson] = useContext(PersonContext);
    const [getPerson, { data: personData }] = useLazyQuery<{
        person: { id: number; name: string; isAdmin: boolean; house: { id: number; familyGroupId: number } };
    }>(GET_PERSON);

    const submitForm = async (values: { id: number; name: string }[]) => {
        getPerson({ variables: { id: values[0].id } });
    };

    useEffect(() => {
        if (personData?.person)
            setPerson({
                id: personData.person.id,
                name: personData.person.name,
                isAdmin: personData.person.isAdmin,
                houseId: personData.person.house.id,
                familyGroupId: personData.person.house.familyGroupId,
            });
    }, [personData]);

    if (person) return <div>{person.name}</div>;

    return (
        <Container>
            <Header title="Login" subtitle="Gemeindefreizeit 2023" hideHamburger={true} />
            <Container>
                <Row>
                    <Col>
                        <PersonSelector
                            onSubmit={(values) => submitForm(values)}
                            placeholder="Dein Name"
                            emptyLabel="Melina hat dich wohl vergessen..."
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
