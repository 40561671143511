import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import dayjs from 'dayjs';
import { CrudList } from '../../components/CrudList';
import { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader';
// import styles from './index.module.scss';

require('dayjs/locale/de');
dayjs.locale('de');

const GET_PURCHASES = gql`
    query {
        purchases {
            id
            deadline
        }
    }
`;

const CREATE_PURCHASE = gql`
    mutation($deadline: String!) {
        createPurchase(deadline: $deadline) {
            id
        }
    }
`;

export default function PurchasesOverview() {
    const { data: purchasesData, loading } = useQuery<{
        purchases: {
            id: number;
            deadline: string;
        }[];
    }>(GET_PURCHASES);
    const [createPurchase] = useMutation<{
        purchases: {
            id: number;
            deadline: string;
        };
    }>(CREATE_PURCHASE);
    const [purchases, setPurchases] = useState<
        {
            id: number;
            deadline: string;
        }[]
    >([]);

    useEffect(() => {
        if (purchasesData?.purchases) setPurchases(purchasesData.purchases);
    }, [purchasesData]);

    const add = async (item: { deadline: dayjs.Dayjs }) => {
        const newPurchaseData = await createPurchase({
            variables: {
                deadline: (+item.deadline).toString(),
            },
        });

        if (newPurchaseData.data?.purchases) setPurchases([...purchases, newPurchaseData.data.purchases]);
    };

    return (
        <Container>
            <Header title="Einkäufe" />
            <Container>
                <Row>
                    <Col>
                        {loading ? (
                            <Loader />
                        ) : (
                            <CrudList data={purchases} onAdd={(item: { deadline: dayjs.Dayjs }) => add(item)} />
                        )}
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
