import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from '../../components/Header';
import styles from './index.module.scss';
import schedule from '../../assets/img/gf-wochenplan-20231021.png';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import WeekSchedule from '../../components/WeekSchedule';
import { Check, Delete, Edit } from '@mui/icons-material';
import { Loader } from '../../components/Loader';

const GET_HOUSES = gql`
    {
        houses {
            id
            name
            houseNumber
        }
    }
`;

const GET_EVENTS = gql`
    {
        events {
            id
            name
            houseId
        }
    }
`;

const CREATE_EVENT = gql`
    mutation($name: String!, $houseId: Int!) {
        createEvent(name: $name, houseId: $houseId) {
            id
            name
            houseId
        }
    }
`;

const DELETE_EVENT = gql`
    mutation($id: Int!) {
        removeEvent(id: $id) {
            id
        }
    }
`;

export default function WeeklySchedule() {
    const [getEvents, { data }] = useLazyQuery<{
        events: { id: number; name: string; houseId: number }[];
    }>(GET_EVENTS, { fetchPolicy: 'cache-and-network' });
    const { data: housesData } = useQuery<{ houses: { id: number; name: string; houseNumber: number }[] }>(GET_HOUSES);

    const [createEvent] = useMutation<{
        createEvent: { id: number; name: string; houseId: number };
    }>(CREATE_EVENT);
    const [deleteEvent] = useMutation(DELETE_EVENT);

    const [isEditing, setIsEditing] = useState(-1);
    const [events, setEvents] = useState<{ id: number; name: string; houseId: number }[]>([]);

    const [currentEventName, setCurrentEventName] = useState('');

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        setEvents(data?.events ?? []);
    }, [data]);

    const reloadEditing = () => {
        const currentVal = isEditing;
        setIsEditing(-1);
        setTimeout(() => {
            setIsEditing(currentVal);
        }, 1);
    };

    const addEvent = async (name: string, houseId: number) => {
        // setEvents([
        //     ...events,
        //     {
        //         id: 1,
        //         name,
        //         houseId,
        //     },
        // ]);

        reloadEditing();

        try {
            const newEvent = await createEvent({
                variables: {
                    name: name,
                    houseId,
                },
            });

            console.log(newEvent);

            if (newEvent.data) setEvents([...events, newEvent.data.createEvent]);
        } catch (e) {
            await getEvents();
            console.error(e);
        }
    };

    const removeEvent = async (id: number) => {
        setEvents(events.filter((item) => item.id !== id));

        reloadEditing();

        try {
            await deleteEvent({
                variables: {
                    id,
                },
            });
        } catch (e) {
            await getEvents();
            console.error(e);
        }
    };

    const eventData: { label: string; entries: [React.ReactNode] }[] = housesData?.houses
        ? housesData.houses.map((house) => {
              const entries = events.filter((event) => event.houseId === house.id);

              return {
                  label: house.houseNumber.toString(),
                  entries: [
                      <div className={styles.wrapper}>
                          <ul className={styles.eventList}>
                              {entries.map((entry) => (
                                  <li key={entry.id}>
                                      {entry.name}
                                      {isEditing === house.id ? <Delete onClick={() => removeEvent(entry.id)} /> : null}
                                  </li>
                              ))}
                              {isEditing === house.id ? (
                                  <>
                                      <input
                                          value={currentEventName}
                                          onChange={(e) => setCurrentEventName(e.target.value)}
                                          onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                  addEvent(currentEventName, house.id);
                                                  setCurrentEventName('');
                                              }
                                          }}
                                      />
                                      <Button
                                          onClick={() => {
                                              addEvent(currentEventName, house.id);
                                              setCurrentEventName('');
                                          }}
                                      >
                                          Hinzufügen
                                      </Button>
                                  </>
                              ) : null}
                          </ul>
                          <div className={styles.utility}>
                              {isEditing === house.id ? (
                                  <Check
                                      onClick={() => {
                                          setIsEditing(-1);
                                      }}
                                  />
                              ) : (
                                  <Edit
                                      onClick={() => {
                                          setIsEditing(house.id);
                                      }}
                                  />
                              )}
                          </div>
                      </div>,
                  ],
              };
          })
        : [];

    return (
        <Container>
            <Header title="Wochenplan" />
            <Container>
                <Row>
                    <Col>
                        <img src={schedule} style={{ width: '100%' }} />
                    </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                    <h2>Was geht ab?</h2>
                    <Col>{data?.events ? <WeekSchedule entriesCount={1} data={eventData} /> : <Loader />}</Col>
                </Row>
            </Container>
        </Container>
    );
}
