import { Check } from '@mui/icons-material';
import styles from './index.module.scss';

export type ItemType = {
    id: number;
    name: string;
    quantity: number;
};

type ItemProps = {
    count?: number;
    state?: boolean;
    onChange?: (state: boolean) => any;
    readonly: boolean;
};

export const Item = (props: ItemType & ItemProps) => {
    const onChange = (state: boolean) => {
        if (props.onChange) props.onChange(state);
    };

    return (
        <div className={styles.item}>
            <div>{props.name}</div>
            <div>{props.count}</div>
            <div>
                <div
                    className={styles.itemButton}
                    onClick={() => {
                        console.log(!(props.state ?? false));
                        onChange(!(props.state ?? false));
                    }}
                >
                    {props.state ? <Check /> : <div></div>}
                </div>
            </div>
        </div>
    );
};
