import './App.scss';
import { Route, Switch } from 'react-router-dom';
import Login from './routes/Login';
import { PersonContext } from './contexts/PersonContext';
import WeeklySchedule from './routes/WeeklySchedule';
import CleaningSchedule from './routes/CleaningSchedule';
import MealPlan from './routes/MealPlan';
import { useContext } from 'react';
import Order from './routes/Order';
import GroceryList from './routes/GroceryList';
import PurchasesOverview from './routes/PurchasesOverview';
import GroceryHouseList from './routes/GroceryHouseList';
import HousePlan from './routes/HousePlan';
import { InstallPWA } from './components/InstallPWA';

export default function App() {
    const [person] = useContext(PersonContext);

    return (
        <>
            <InstallPWA />
            {!person ? (
                <Login />
            ) : (
                <Switch>
                    <Route path="/purchase/:purchaseId/grocery-house-list" component={GroceryHouseList} />
                    <Route path="/purchase/:purchaseId/grocery-list" component={GroceryList} />
                    <Route path="/purchases" component={PurchasesOverview} />
                    <Route path="/house-plan" component={HousePlan} />
                    <Route path="/order" component={Order} />
                    <Route path="/meal-plan" component={MealPlan} />
                    <Route path="/cleaning-schedule" component={CleaningSchedule} />
                    <Route path="/weekly-schedule" component={WeeklySchedule} />
                    <Route path="/" component={WeeklySchedule} />
                </Switch>
            )}
        </>
    );
}
