import { useMemo, useState } from 'react';
import { Toast } from 'react-bootstrap';
import styles from './index.module.scss';

export function ErrorToast(props: { message: string; clearError: () => void }) {
    const [show, setShow] = useState(false);

    useMemo(() => {
        if (props.message !== '') setShow(true);
    }, [props]);

    const close = () => {
        setShow(false);
        props.clearError();
    };

    return (
        <Toast
            className={styles.toast}
            onClose={() => close()}
            show={show}
            delay={3000}
            animation={true}
            autohide={true}
        >
            <Toast.Header className={styles.toastHeader}>
                <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body className={styles.toastBody}>{props.message}</Toast.Body>
        </Toast>
    );
}
