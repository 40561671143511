import { Container } from 'react-bootstrap';
import styles from './index.module.scss';
import logo from '../../assets/img/connect-logo.png';
import Hamburger from '../Hamburger';
import { useContext } from 'react';
import { PersonContext } from '../../contexts/PersonContext';
import { useOnlineStatus } from '../../contexts/OnlineStatusContext';

type Props = {
    title: string;
    subtitle?: string;
    hideHamburger?: boolean;
};

export default function Header(props: Props) {
    const [person, setPerson] = useContext(PersonContext);
    const isOnline = useOnlineStatus();

    const menuLinks = [
        { label: 'Wochenplan', link: '/weekly-schedule' },
        { label: 'Putzplan', link: '/cleaning-schedule' },
        { label: 'Essensplan', link: '/meal-plan' },
        { label: 'Hausplan', link: '/house-plan' },
        // ...(person?.isAdmin && isOnline
        //     ? [
        //           { label: 'Bestellung', link: '/order' },
        //           { label: 'Einkaufsliste', link: '/purchases' },
        //           //   { label: 'Geschirr', link: '/' },
        //       ]
        //     : []),
    ];

    return (
        <Container className={styles.header}>
            {props.hideHamburger ? (
                <div></div>
            ) : (
                <Hamburger
                    items={menuLinks}
                    onLogout={() => {
                        localStorage.removeItem('personLoggedIn'), setPerson(null);
                    }}
                />
            )}
            <div className={styles.title}>
                <h1>{props.title}</h1>
                {props.subtitle ? <p className="subtitle">{props.subtitle}</p> : null}
                {!isOnline ? (
                    <p className="subtitle" style={{ color: '#f00' }}>
                        Du bist offline
                        <br />
                        Möglicherweise sind die Daten nicht aktuell
                    </p>
                ) : null}
            </div>
            <img src={logo} width={40} />
        </Container>
    );
}
