import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Popup from '../Popup';
import { detect } from 'detect-browser';
const browser = detect();

export function InstallPWA() {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState<Event | null>(null);

    const [open, setOpen] = useState(true);
    const [browserType, setBrowserType] = useState('default');

    useEffect(() => {
        // If PWA is not installed
        if (!window.matchMedia('(display-mode: standalone)').matches) {
            // Mostly only works with Chrome: https://caniuse.com/mdn-api_beforeinstallpromptevent
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                console.log('we are being triggered :D');
                setSupportsPWA(true);
                setPromptInstall(e);
            });

            // Fallback for Safari
            if (browser?.name === 'ios') {
                setBrowserType('safari');
                setSupportsPWA(true);
            }

            // Fallback for Firefox
            if (browser?.name === 'firefox') {
                setBrowserType('firefox');
                setSupportsPWA(true);
            }
        }
    }, []);

    if (!supportsPWA) {
        return null;
    }

    let popupContent: JSX.Element | null = null;

    switch (browserType) {
        case 'safari': {
            popupContent = (
                <>
                    <p>Du kannst dir die App jetzt direkt installieren.</p>
                    <p>1. Unten in der Mitte auf 'Teilen' klicken</p>
                    <p>2. 'Zum Home-Bildschirm' auswählen</p>
                    <Button
                        className="link-button"
                        id="setup_button"
                        aria-label="Install app"
                        title="Install app"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Verstanden
                    </Button>
                </>
            );
            break;
        }

        case 'firefox': {
            popupContent = (
                <>
                    <p>Du kannst dir die App jetzt direkt installieren.</p>
                    <p>1. Auf '⁝' klicken</p>
                    <p>2. 'Installieren' auswählen</p>
                    <Button
                        className="link-button"
                        id="setup_button"
                        aria-label="Install app"
                        title="Install app"
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Verstanden
                    </Button>
                </>
            );
            break;
        }

        default: {
            popupContent = (
                <>
                    <p>Du kannst dir die App jetzt direkt installieren.</p>
                    <p>Klicke einfach auf den Button und akzeptiere die Anfrage</p>
                    <Button
                        className="link-button"
                        id="setup_button"
                        aria-label="Install app"
                        title="Install app"
                        onClick={async (e) => {
                            e.preventDefault();
                            if (!promptInstall) {
                                return;
                            }
                            // @ts-ignore
                            promptInstall.prompt();
                            // @ts-ignore
                            await promptInstall.userChoice;
                            setOpen(false);
                        }}
                    >
                        Jetzt Installieren
                    </Button>
                </>
            );
            break;
        }
    }

    return (
        <Popup isOpen={open} onClose={() => setOpen(false)}>
            {popupContent}
        </Popup>
    );
}
