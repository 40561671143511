import { Col, Container, Row } from 'react-bootstrap';
import styles from './index.module.scss';
import React from 'react';

type Tuple<T, N extends number> = N extends N ? (number extends N ? T[] : _TupleOf<T, N, []>) : never;
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N ? R : _TupleOf<T, N, [T, ...R]>;

type Props<Length extends number> = {
    entriesCount: Length;
    columnLabels?: Tuple<string, Length>;
    data: {
        label: string;
        entries: Tuple<React.ReactNode, Length>;
    }[];
};

export default function WeekSchedule<Length extends number>(props: Props<Length>) {
    return (
        <Container className={styles.plan}>
            <Row>
                <Col className={styles.table} style={{ '--entry-count': props.entriesCount } as React.CSSProperties}>
                    {props.data.map((row) => (
                        <>
                            <div className={styles.label}>{row.label}</div>
                            <div className={styles.column}>
                                {row.entries.map((col, index) => (
                                    <div className={styles.entry}>
                                        {props.columnLabels ? (
                                            <div className={styles.columnLabel}>{props.columnLabels[index]}</div>
                                        ) : null}
                                        {col}
                                    </div>
                                ))}
                            </div>
                        </>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
