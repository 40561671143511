import { useEffect, useState } from 'react';
import { BounceLoader } from 'react-spinners';

export function Loader() {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 500);
    }, []);

    return (
        <div
            style={{
                width: '100%',
                position: 'relative',
                height: 100,
                padding: 20,
            }}
        >
            <BounceLoader
                color="#00B0EE"
                size={100}
                loading={show}
                css="position: absolute; left: 50%; transform: translateX(-50%);"
            />
        </div>
    );
}
